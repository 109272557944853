import React from "react"
import Helmet from 'react-helmet'

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import Countdown from 'react-countdown';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <div className="page__content fs-large"><span className="countdown">We are open.<br/>Please reload the page.</span></div>;
  } else {
    // Render a countdown
    return <div className="page__content fs-large"><span className="countdown">{("0" + days).slice(-2)}D {("0" + hours).slice(-2)}H {("0" + minutes).slice(-2)}M {("0" + seconds).slice(-2)}S</span></div>;
  }
};

const IndexPage = () => (
  <Layout>
    <SEO title="Marine Serre Archive" />
    <Helmet
      bodyAttributes={{
          class: 'countdown-page'
      }}
    />
    <Header siteTitle="Marine Serre Archive" />
    <Countdown date={new Date('December 2, 2020 22:00:00 GMT+01:00')} renderer={renderer}>
    </Countdown>
  </Layout>
)

export default IndexPage
